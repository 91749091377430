import {Component, OnInit} from '@angular/core';
import {first} from "rxjs/operators";
import {Props} from "./_models/props";
import {PropertyService} from "./_services/property.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'auth';
  image:string;

  constructor(private propertyService: PropertyService, private router:Router) {}

  ngOnInit(): void {
    this.propertyService.getProperty("front.background.image")
      .pipe(first())
      .subscribe(
        (url: Props) => {
          this.image = url.value;
        },
        httpErrorResponse => {
          this.image = null;
        });
  }
}
