import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ErrorComponent } from "./error/error.component";
import { InternalAuthorizationComponent } from "./internal-authorization/internal-authorization.component";


const routes: Routes = [
  { path: 'login/error', component: ErrorComponent },
  { path: 'login/internal-auth', component: InternalAuthorizationComponent },

  // otherwise redirect to home
  { path: '**', redirectTo: 'login/error'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
