import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

export interface IErrorCodeMessage {
  title: string;
  message: string;
}

@Injectable()
export class InternalAuthorizationService {

  constructor(private _http: HttpClient) { }

  postInternalAuth(login, pass: string): Observable<any> {
    return this._http.post('/authenticate/internal_auth', { login: login, password: pass});
  }

}
