import { Component, OnInit } from '@angular/core';
import { IErrorCodeMessage, InternalAuthorizationService } from "../_services/internal-authorization.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthResult } from "../_models/auth-result";
import { Router } from "@angular/router";
import { first } from "rxjs/operators";

@Component({
  selector: 'app-internal-authorization',
  templateUrl: './internal-authorization.component.html',
  styleUrls: ['./internal-authorization.component.scss']
})
export class InternalAuthorizationComponent implements OnInit {

  loading = false;
  loginForm: FormGroup;

  public message: IErrorCodeMessage = {
    title: null,
    message: null,
  };

  public errorResponse: string = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private _internalAuthorizationService: InternalAuthorizationService) { }

  get f() { return this.loginForm.controls; }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onApproveClick(): void {
    this.loading = true;
    this.errorResponse = null;
    this._internalAuthorizationService.postInternalAuth(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        (authResult: AuthResult) => {
          window.location.href = authResult.redirect;
          this.router.navigate([authResult.redirect]);
        },
        httpErrorResponse => {
          this.errorResponse = httpErrorResponse.status == 403 ? httpErrorResponse.error.message: 'Сервис авторизации временно недоступен';
          this.loading = false;
        }
      )
  }

}
