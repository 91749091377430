import { Injectable } from '@angular/core';
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(private http: HttpClient) { }

  getError(code: string) {
    return this.http.get(`authenticate/errorcode?code=`+code)
      .pipe(map(props => {
        return props;
      }));
  }
}
