import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PropertyService {
  constructor(private http: HttpClient) { }

  getProperty(key: string) {
    return this.http.get(`/property?key=`+key)
    .pipe(map(props => {
        return props;
      }));
    }
}
